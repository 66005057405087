<template>
    <layout>
        <v-container class="calculatorfront mb-16">
            <breadcrumbs :items="breadcrumbs" :large="true" class="py-md-7 d-none d-md-block" textcolor="textdark"></breadcrumbs>
            <calculator @step1="completed" :redir="true"/>
        </v-container>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/LightTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import Calculator from '@/components/pages/Calculator.vue'

export default {
    components: {
        Layout,
        Breadcrumbs,
        Calculator
    },
    data: () => ({
        track_number: '',
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Get quotes',
                disabled: true,
            }
        ]
    }),
    methods: {
        completed(){
            this.$router.push({name: 'account.quote'})
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@media all and (max-width: 960px){
    .main.bg-light, .calculatorfront{
        background: #fff;
    }
}
</style>
